@-moz-keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  font: 16px/1.5 'PT Sans', sans-serif;
}
body h1,
body h2,
body h3 {
  font-family: 'PT Serif', serif;
  font-weight: normal;
}
input,
select,
textarea,
button {
  font: 1em 'PT Sans', sans-serif;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden] {
  display: none;
}
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
a:focus {
  outline: thin dotted;
}
a:active,
a:hover {
  outline: 0;
}
h1 {
  font-size: 2em;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: 700;
}
dfn {
  font-style: italic;
}
mark {
  background: #ff0;
  color: #000;
}
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
q {
  quotes: 2 1C 2 1D 2 18 2 19;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}
button,
input {
  line-height: normal;
}
button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
input[disabled] {
  cursor: default;
}
input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}
input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body,
figure {
  margin: 0;
}
legend,
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.card-page table.antropometric-data {
  border-collapse: collapse;
  font-size: 0.9em;
}
.card-page table.antropometric-data th {
  background: #808080;
  color: #fff;
  font-weight: normal;
  padding: 0 4px;
}
.card-page table.antropometric-data input,
.card-page table.antropometric-data button {
  width: 6em;
}
.card-page table.antropometric-data td.input,
.card-page table.antropometric-data td.value {
  text-align: center;
}
.card-page table.antropometric-data td.calculated {
  background: #eee;
}
.front-section-antropometry table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #808080;
}
.front-section-antropometry table th,
.front-section-antropometry table td {
  border: 1px solid #808080;
  padding: 4px 8px;
}
.front-section-antropometry table th.value,
.front-section-antropometry table td.value {
  width: 6em;
  text-align: center;
}
.front-section-antropometry table th.value.calculated,
.front-section-antropometry table td.value.calculated {
  background: #eee;
}
.front-section-antropometry table th.value {
  font-size: 0.85em;
}
.big-text pre.comment,
.big-text textarea.comment {
  font-family: 'PT Sans', sans-serif;
}
.big-text pre.comment {
  margin: 0 0 10px;
}
.big-text textarea.comment {
  display: block;
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
}
.patient-card__body {
  border-top: 1px solid #808080;
  margin-top: 10px;
}
.card-info ul.card-reminders {
  list-style: none;
  padding: 0;
}
.card-info ul.card-reminders li > div {
  display: flex;
  padding: 2px 4px;
}
.card-info ul.card-reminders li > div:hover {
  background: #eee;
}
.card-info ul.card-reminders li > div .title {
  flex: 1;
}
.card-info ul.card-reminders li > div .alert {
  color: #f00;
}
.card-info a {
  color: #646464;
  text-decoration: none;
}
.card-info a:hover {
  color: #242424;
  text-decoration: underline;
}
.card-info ul.actions {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
}
ul.card-links {
  min-width: 256px;
  list-style: none;
  padding: 0;
  margin: 1em 0;
}
ul.card-links li.main {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #808080;
}
ul.card-links li > div.active {
  background: #ffc600;
}
ul.card-links li > div.active a:link,
ul.card-links li > div.active a:visited {
  color: #242424;
}
ul.card-links li a {
  display: block;
  padding: 0 5px;
  outline: none;
}
ul.card-links li a:link,
ul.card-links li a:visited {
  text-decoration: none;
  color: #646464;
}
ul.card-links li a:hover {
  text-decoration: underline;
  color: #242424;
}
.card-page {
  margin-left: 10px;
  border-left: 1px solid #808080;
  padding-left: 10px;
  flex: 1;
}
.list-editor {
  font-size: 0.9em;
}
.list-editor > * {
  margin: 5px 0;
}
.list-editor-actions {
  text-align: right;
}
.list-editor-item-actions button {
  padding: 0;
  width: 1.5em;
  height: 1.5em;
  outline: none;
  border: none;
  border-radius: 2px;
  background: none;
  color: #808080;
}
.list-editor-item-actions button:hover {
  background: #ffc600;
  color: #000;
}
.list-editor-item-actions button:hover .fa-remove {
  color: #f00;
}
.strings-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.strings-item > input {
  flex: 1;
  margin-right: 4px;
}
.params-item .params-item-title {
  display: flex;
  align-items: center;
}
.params-item .params-item-title > input {
  flex: 1;
}
.params-item .params-item-title > label {
  margin: 0 10px;
}
.params-item .params-item-title .diagnosis-code {
  max-width: 6em;
}
.params-item .params-item-options {
  margin: 5px 0;
  padding: 5px;
  background: #eee;
  display: flex;
}
.params-item .params-item-options textarea {
  margin: 0 0 0 5px;
  flex: 1;
}
.params-item .params-item-options ul {
  margin: 0 5px;
  padding: 0;
  list-style: none;
}
.params-item .params-item-options ul input,
.params-item .params-item-options ul button {
  padding: 0;
}
.params-item .params-item-options ul input {
  width: 18em;
}
.params-item .params-item-options ul button {
  margin: 0 5px;
  border: none;
  background: none;
  color: #808080;
}
.params-item .params-item-options ul button:hover {
  color: #000;
}
.params-item .params-item-options ul button:hover .fa-remove {
  color: #f00;
}
.page-consultations .form {
  border: 1px solid #808080;
  padding: 8px;
  background: #eee;
}
.page-consultations label {
  margin-right: 4px;
}
.consultation-form .header,
.consultation-item .header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 8px;
}
.consultation-form .header > *,
.consultation-item .header > * {
  white-space: nowrap;
}
.consultation-form > .header {
  margin-bottom: 8px;
}
.consultation-form > label {
  display: block;
}
.consultation-form > textarea {
  width: 100%;
  margin-bottom: 8px;
}
.buttons {
  display: flex;
  gap: 8px;
}
.consultation-item {
  margin-top: 8px;
  border: 1px solid #808080;
  padding: 8px;
}
.consultation-item label {
  color: #808080;
}
.consultation-item > label {
  display: block;
  margin-top: 8px;
}
.front-section-diagnosis ul,
.params-list.diagnosis ul {
  padding-left: 2em;
}
.params-list.diagnosis span.code,
.params-editor.diagnosis span.code {
  display: inline-block;
  width: 3em;
  font-weight: bold;
}
.params-list.diagnosis span.comment,
.params-editor.diagnosis span.comment,
.params-list.diagnosis div.comment,
.params-editor.diagnosis div.comment {
  padding-left: 5em;
  color: #646464;
}
.params-list.diagnosis span.comment,
.params-list.diagnosis div.comment {
  padding-left: 3em;
  color: #646464;
}
.params-editor.diagnosis label {
  display: flex;
  align-items: baseline;
}
.params-editor.diagnosis label input {
  width: 2em;
}
.params-editor.diagnosis label span.code {
  width: 3em;
}
.params-editor.diagnosis label span.title {
  flex: 1;
}
.front-section-diagnosis ul {
  padding-left: 2rem;
}
.front-section-diagnosis ul li > div {
  display: flex;
}
.front-section-diagnosis ul span.code {
  width: 3rem;
}
.front-section-diagnosis ul span.comment {
  padding-left: 3rem;
  color: #646464;
}
.list-editor-items > * {
  margin: 5px 0;
}
.card-page .params-list ul {
  font-size: 0.9em;
  padding-left: 2em;
}
.card-page .params-list ul .param-option {
  margin-left: 0.5em;
  color: #808080;
}
.card-page .params-list ul pre.param-comment {
  margin: 0;
  color: #808080;
  font-family: 'PT Sans', sans-serif;
}
.card-page .params-editor ul {
  list-style: none;
  font-size: 0.9em;
  padding-left: 0;
}
.card-page .params-editor ul li:hover {
  background: #d9d9d9;
}
.card-page .params-editor ul li > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.card-page .params-editor ul li > div > span {
  flex: 1;
}
.card-page .params-editor ul li input {
  width: 2em;
}
.card-page .params-editor ul li label {
  margin-right: 0.5em;
}
.card-page .params-editor ul li > textarea {
  width: 100%;
}
.front-section-params .params-list ul {
  padding: 8px;
  padding-left: 2em;
}
.front-section-params .params-list ul li span.param-option {
  margin-left: 0.5em;
}
.front-section-params .params-list ul li pre {
  font-family: 'PT Sans', sans-serif;
  margin: 0;
  color: #646464;
}
.patient-editor {
  width: 360px;
  margin: 0 auto;
}
.patient-editor form {
  display: flex;
  flex-direction: column;
}
.patient-editor form > * {
  margin-bottom: 10px;
}
.patient-editor form > div {
  display: flex;
  flex-direction: row;
}
.patient-editor form > div button {
  width: 50%;
}
.patient-picker__search {
  display: block;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #808080;
  font-size: 1.5em;
}
.patient-picker-list {
  list-style: none;
  padding: 0;
}
.patient-picker-list li {
  padding: 2px 4px;
}
.patient-picker-list li a {
  text-decoration: none;
  color: #242424;
}
.patient-picker-list li:hover {
  background: #ffc600;
  color: #242424;
}
.page-procedures .form {
  border: 1px solid #808080;
  padding: 8px;
  background: #eee;
}
.page-procedures label {
  margin-right: 4px;
}
.procedure-form .header,
.procedure-item .header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 8px;
}
.procedure-form .header > *,
.procedure-item .header > * {
  white-space: nowrap;
}
.procedure-form > .header {
  margin-bottom: 8px;
}
.procedure-form > label {
  display: block;
}
.procedure-form > textarea {
  width: 100%;
  margin-bottom: 8px;
}
.buttons {
  display: flex;
  gap: 8px;
}
.procedure-item {
  margin-top: 8px;
  border: 1px solid #808080;
  padding: 8px;
}
.procedure-item label {
  color: #808080;
}
.procedure-item > label {
  display: block;
  margin-top: 8px;
}
.profile-prop {
  margin-right: 10px;
  border: 1px solid #808080;
  display: flex;
  flex-direction: row;
}
.profile-prop .label,
.profile-prop .value {
  font-size: 0.9em;
  padding: 2px 10px;
}
.profile-prop .label {
  background: #808080;
  color: #fff;
}
.front-profile table {
  border-collapse: collapse;
  border: 1px solid #808080;
  width: 50%;
}
.front-profile table td,
.front-profile table th {
  border: 1px solid #808080;
  padding: 4px 8px;
}
.front-profile table th {
  width: 8em;
  text-align: right;
}
.card-page table.meals {
  border-collapse: collapse;
  font-size: 0.9em;
  width: 100%;
  margin-bottom: 10px;
}
.card-page table.meals pre.comment {
  margin: 0;
  font-family: 'PT Sans', sans-serif;
}
.card-page table.meals th {
  padding: 2px 4px;
  background: #808080;
  color: #fff;
  font-weight: normal;
}
.card-page table.meals td {
  vertical-align: top;
}
.card-page table.meals td.index {
  width: 2em;
  text-align: center;
}
.card-page table.meals td.time {
  width: 5em;
  text-align: center;
}
.card-page table.meals td.actions {
  width: 6em;
}
.card-page table.meals td textarea.comment {
  width: 100%;
}
.front-section-ration table.meals {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #808080;
}
.front-section-ration table.meals th,
.front-section-ration table.meals td {
  padding: 4px 8px;
  border: 1px solid #808080;
}
.front-section-ration table.meals th.index,
.front-section-ration table.meals td.index,
.front-section-ration table.meals th.time,
.front-section-ration table.meals td.time {
  width: 2em;
  text-align: center;
}
.front-section-ration table.meals th pre,
.front-section-ration table.meals td pre {
  margin: 0;
}
.front-section-ration table.meals td {
  vertical-align: top;
}
.screen-body {
  border-top: 1px solid #808080;
  display: flex;
  flex-direction: row;
}
.screen-body .screen-body-sidebar {
  width: 30%;
}
.screen-body .screen-body-sidebar > ul {
  list-style: none;
  margin: 1em 0;
  padding: 0;
}
.screen-body .screen-body-sidebar > ul li > div.active {
  background: #ffc600;
}
.screen-body .screen-body-sidebar > ul li > div.active a:link,
.screen-body .screen-body-sidebar > ul li > div.active a:visited {
  color: #242424;
}
.screen-body .screen-body-sidebar > ul li a {
  display: block;
  padding: 0 5px;
  outline: none;
}
.screen-body .screen-body-sidebar > ul li a:link,
.screen-body .screen-body-sidebar > ul li a:visited {
  text-decoration: none;
  color: #646464;
}
.screen-body .screen-body-sidebar > ul li a:hover {
  text-decoration: underline;
  color: #242424;
}
.screen-body .screen-body-content {
  flex: 1;
  margin-left: 10px;
  border-left: 1px solid #808080;
  padding-left: 10px;
}
ul.tests-links {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #808080;
}
ul.tests-links li > a {
  outline: none;
  display: block;
  padding: 4px 10px;
  text-decoration: none;
  color: #242424;
}
ul.tests-links li > a:hover {
  text-decoration: underline;
}
ul.tests-links li.active > a {
  background: #ffc600;
}
.tests-create-form label {
  display: flex;
  align-items: center;
}
.tests-create-form input[type=checkbox] {
  flex: 0 0 2em;
}
ul.history {
  list-style: none;
  padding: 0;
}
ul.history ul.tests-item-history {
  margin-top: 5px;
  margin-bottom: 10px;
  list-style: none;
}
ul.history ul.tests-item-history li {
  display: flex;
  flex-direction: row;
}
ul.history ul.tests-item-history li .date {
  color: #646464;
  margin-right: 10px;
}
.test-value {
  display: flex;
  justify-content: center;
  align-items: center;
}
.test-value .date {
  color: #646464;
  font-size: 0.8em;
  padding-right: 0.4em;
}
.tests-history-table,
.tests-pending-form {
  font-size: 0.9em;
}
.tests-history-table table,
.tests-pending-form table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 10px;
}
.tests-history-table table th,
.tests-pending-form table th,
.tests-history-table table td,
.tests-pending-form table td {
  border: 1px solid #808080;
  padding: 2px 4px;
}
.tests-history-table table th.input,
.tests-pending-form table th.input,
.tests-history-table table td.input,
.tests-pending-form table td.input,
.tests-history-table table th.value,
.tests-pending-form table th.value,
.tests-history-table table td.value,
.tests-pending-form table td.value {
  width: 8em;
  text-align: center;
}
.tests-history-table table th input.comment,
.tests-pending-form table th input.comment,
.tests-history-table table td input.comment,
.tests-pending-form table td input.comment,
.tests-history-table table th.input input,
.tests-pending-form table th.input input,
.tests-history-table table td.input input,
.tests-pending-form table td.input input {
  width: 100%;
  padding: 0;
}
.tests-history-table table tr.group-header,
.tests-pending-form table tr.group-header {
  background: #eee;
}
.tests-history-table table td.date,
.tests-history-table table th.date {
  width: 8em;
  text-align: center;
}
.tests-history-table table td.value,
.tests-history-table table th.value {
  width: 6em;
}
.tests-history-table table td.date {
  color: #646464;
}
.tests-history-table label {
  flex: 1;
}
.tests-history-table label .group-title {
  color: #646464;
}
.tests-history-table ul {
  list-style: none;
  padding: 0;
}
.tests-history-table ul li {
  display: flex;
  flex-direction: row;
}
ul.tests-list {
  list-style: none;
  padding: 0;
}
ul.tests-list li input {
  width: 2em;
}
.badge {
  font-decoration: none;
  display: inline-block;
  border-radius: 3px;
  background: #808080;
  color: #fff;
  font-size: 10px;
  line-height: 15px;
  width: 15px;
  text-align: center;
  margin-left: 5px;
  transform: translateY(-2px);
}
.front-section-labtests table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #808080;
}
.front-section-labtests table tr.group-span {
  background: #eee;
}
.front-section-labtests table th,
.front-section-labtests table td {
  border: 1px solid #808080;
  padding: 4px 8px;
}
.front-section-labtests table th.date,
.front-section-labtests table td.date,
.front-section-labtests table th.value,
.front-section-labtests table td.value {
  width: 6em;
}
.front-section-labtests table td.date {
  font-size: 0.85em;
}
.front-section-labtests table td.date,
.front-section-labtests table td.value {
  text-align: center;
}
table.admin-table tr.group-header {
  background: #eee;
}
table.admin-table td.input,
table.admin-table td.value {
  width: 8em;
}
table.admin-table td.input input {
  width: 8em;
}
table.admin-table input.comment,
table.admin-table textarea.comment {
  width: 100%;
}
form .actions {
  display: flex;
}
form .actions label {
  flex: 1;
  text-align: right;
}
.tests-pending-form td.title span.reminder {
  font-size: 0.9em;
  float: right;
}
.tests-create-form {
  font-size: 0.9em;
}
.tests-create-form span.notes {
  color: #646464;
  padding-left: 0.5em;
}
.tests-create-form span.spring {
  flex: 1;
}
.test-value.expired .date {
  color: #f00;
}
body {
  margin: 0;
  padding: 0;
}
div.app {
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}
.inner {
  padding: 10px;
  max-width: 1000px;
  margin: 0 auto;
}
.inner .narrow {
  max-width: 480px;
  margin: 0 auto;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
header {
  position: sticky;
  top: 0;
  width: 100%;
  background: #242424;
  color: #efefef;
}
header a.home {
  color: #efefef;
  text-decoration: none;
  outline: none;
}
header .inner {
  align-items: center;
}
header img.logo {
  height: 3rem;
  transform: scale(3);
  animation: app-logo-spin infinite 60s linear;
}
header .yellow {
  color: #ffc600;
}
header .version {
  color: #646464;
  vertical-align: super;
  font-size: 0.5em;
}
header h1 {
  margin: 0;
}
footer {
  background: #242424;
  color: #efefef;
  font-size: 0.8em;
}
span.no-data {
  font-size: 0.85em;
  color: #646464;
}
pre.comment {
  font-family: 'PT Sans', sans-serif;
}
nav {
  background: #d9d9d9;
  text-align: right;
  padding: 4px;
  border: 1px solid #808080;
}
nav a {
  display: inline-block;
  padding: 2px 8px;
  background: #efefef;
  color: #000;
}
nav a:link {
  text-decoration: none;
}
nav a:hover {
  text-decoration: underline;
  background: #ffc600;
}
table.admin-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
  margin-bottom: 10px;
}
table.admin-table tr.group-header {
  background: #eee;
}
table.admin-table th,
table.admin-table td {
  border: 1px solid #808080;
  padding: 2px 4px;
}
.yellow-button {
  padding: 0;
  border: 0;
  margin: 0;
  background: none;
  font-size: 1em;
  line-height: 1.5em;
  text-align: center;
  color: #efefef;
  text-decoration: none;
  cursor: pointer;
  width: 1.5em;
  height: 1.5em;
  border-radius: 2px;
}
.yellow-button:hover {
  background: #ffc600;
  color: #242424;
}
@media print {
  div.app {
    overflow: unset;
  }
  header,
  nav {
    display: none;
  }
}
